<template>
  <div class="box-style">
    <div class="flex">
      <div class="w-56">
        <img :src="plan_image" alt="" />
      </div>
      <div>
        <div class="secondhead-text">My Plan</div>
        <div class="subhead-text">
          This is where you can prepare your plan and track your progress.
        </div>
      </div>
    </div>
    <div class="border border-cool-gray my-2"></div>
    <div>
      <div class="py-2.5">
        <font-awesome-icon
          :icon="plan.plan_loading ? 'spinner' : 'info-circle'"
          :spin="plan.plan_loading"
          class="mr-2 text-purple text-2xl"
        />
        {{
          plan.plan_loading
            ? "Checking plan status..."
            : "Your personal and professional development plan."
        }}
      </div>
      <div class="flex gap-8 justify-center">
        <div
          v-for="mentor in $store.state.student.mentors.sort(
            (a, b) => a.lastname > b.lastname
          )"
          :key="mentor.mentor_id"
          class=""
        >
          <button
            data-cy="plan-progress-button"
            v-if="!plan.plan_loading"
            class="py-2 px-4 bg-purple hover:bg-dark-purple rounded-full text-white"
            @click="navigate(mentor.mentor_id)"
          >
            {{
              (plan.plans[mentor.mentor_id] &&
                plan.plans[mentor.mentor_id].status === "COMPLETE") ||
              this.$store.state.user_selected_term.name !==
                this.lookup_current_term.name
                ? "View My Plan for "
                : plan.plans[mentor.mentor_id]
                ? "Edit Plan for "
                : "Get Started with "
            }}
            {{ mentor.prefix }} {{ mentor.firstname }} {{ mentor.lastname }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "PlanProgress",
  data() {
    return {
      edit_path: "/plan/edit",
      view_path: "/plan/view",
      plan_image: require("@/../public/icons-1.3/woman-logs@2x.png"),
    };
  },
  created() {
    // this.fetchPlanResource(`L${this.acad_lvl}`);
  },
  watch: {
    getAcadLevel: {
      handler() {
        this.fetchStudentResource();
      },
    },
  },
  methods: {
    navigate(mentor_id) {
      if (!this.plan.plans[mentor_id]) {
        this.$router.push(this.edit_path + "/" + mentor_id);
      } else if (
        this.plan.plans[mentor_id].status !== "COMPLETE"
      ) {
        this.$router.push(this.edit_path + "/" + mentor_id);
      } else {
        this.$router.push(this.view_path + "/" + mentor_id);
      }
    },
    ...mapActions(["postPlanResource", "fetchPlanResource"]),
  },
  computed: {
    ...mapGetters(["student_profile", "lookup_current_term", "getAcadLevel",]),
    ...mapState({plan: (state) => state.plan}),
  },
};
</script>
